import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "filterBtn",
    "secondaryFilter",
    "industryFilters",
    "projectAttributeFilters",
    "secondaryFilterBtn",
    "case",
    "showMore",
    "none",
  ];

  static values = {
    selectedIndustries: { type: Array, default: [] },
    selectedProjectAttributes: { type: Array, default: [] },
    showMore: Boolean,
  };

  connect() {
    this.currentlySelected = "showAllBtn";
    const showAllInput = document.getElementById("showAllBtn");
    if (showAllInput) {
      showAllInput.checked = true;
      const showAllLabel = document.querySelector(`label[for="showAllBtn"]`);
      if (showAllLabel) {
        showAllLabel.classList.add("active");
      }
    }
    this.filter();
  }

  toggle(event) {
    const input = document.getElementById(event.target.getAttribute("for"));

    if (this.currentlySelected === input.id) {
      event.preventDefault();
      input.checked = false;
      this.currentlySelected = null;
      event.target.classList.remove("active");
      this.secondaryFilterTargets.forEach((filters) => {
        filters.classList.remove("show");
      });
      this.selectedIndustriesValue = [];
      this.selectedProjectAttributesValue = [];
      this.showMoreValue = false;
      this.filter();
      return;
    }

    this.filterBtnTargets.forEach((btn) => {
      btn.classList.remove("active");
    });

    event.target.classList.add("active");
    this.currentlySelected = input.id;

    if (input.id === "showAllBtn") {
      this.industryFiltersTarget.classList.remove("show");
      this.projectAttributeFiltersTarget.classList.remove("show");
      this.selectedIndustriesValue = [];
      this.selectedProjectAttributesValue = [];
      this.showMoreValue = false;
      this.filter();
    } else if (input.id === "industryBtn") {
      this.industryFiltersTarget.classList.add("show");
      this.projectAttributeFiltersTarget.classList.remove("show");
    } else if (input.id === "projectAttributeBtn") {
      this.industryFiltersTarget.classList.remove("show");
      this.projectAttributeFiltersTarget.classList.add("show");
    }
  }

  toggleSecondary(event) {
    const button = event.target;
    const filterValue = button.textContent.trim();

    button.classList.toggle("active");

    if (this.currentlySelected === "industryBtn") {
      if (button.classList.contains("active")) {
        this.selectedIndustriesValue = [
          ...this.selectedIndustriesValue,
          filterValue,
        ];
      } else {
        this.selectedIndustriesValue = this.selectedIndustriesValue.filter(
          (v) => v !== filterValue
        );
      }
    } else if (this.currentlySelected === "projectAttributeBtn") {
      if (button.classList.contains("active")) {
        this.selectedProjectAttributesValue = [
          ...this.selectedProjectAttributesValue,
          filterValue,
        ];
      } else {
        this.selectedProjectAttributesValue =
          this.selectedProjectAttributesValue.filter((v) => v !== filterValue);
      }
    }

    this.showMoreValue = false;
    this.filter();
  }

  showMoreCaseStudies() {
    this.showMoreValue = true;
    this.filter();
  }

  filter() {
    const hasIndustryFilters = this.selectedIndustriesValue.length > 0;
    const hasProjectAttributeFilters =
      this.selectedProjectAttributesValue.length > 0;

    let visibleCases = [];

    this.caseTargets.forEach((caseStudy) => {
      const industry = caseStudy.dataset.industry;
      const projectAttributes = caseStudy.dataset.projectAttributes
        .split(";")
        .map((attr) => attr.trim());

      let showCase = true;

      if (hasIndustryFilters) {
        showCase = showCase && this.selectedIndustriesValue.includes(industry);
      }

      if (hasProjectAttributeFilters) {
        showCase =
          showCase &&
          this.selectedProjectAttributesValue.some((attr) =>
            projectAttributes.includes(attr)
          );
      }

      if (!hasIndustryFilters && !hasProjectAttributeFilters) {
        showCase = true;
      }

      if (showCase) {
        visibleCases.push(caseStudy);
      }
    });

    this.caseTargets.forEach((caseStudy) => {
      caseStudy.classList.add("case--hidden");
    });

    if (visibleCases.length > 0) {
      this.noneTargets.forEach((none) => none.classList.add("case--hidden"));
    } else {
      this.noneTargets.forEach((none) => none.classList.remove("case--hidden"));
    }

    const firstCases = visibleCases.slice(0, 5);
    visibleCases.splice(0, 5);

    firstCases.forEach((caseStudy) => {
      caseStudy.classList.remove("case--hidden");
    });

    if (visibleCases.length > 0 && !this.showMoreValue) {
      this.showMoreTargets.forEach((showMore) =>
        showMore.classList.remove("case--hidden")
      );
    } else {
      this.showMoreTargets.forEach((showMore) =>
        showMore.classList.add("case--hidden")
      );
    }

    if (this.showMoreValue) {
      visibleCases.forEach((caseStudy) => {
        caseStudy.classList.remove("case--hidden");
      });
    }
  }
}
